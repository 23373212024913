.not-found {
  text-align: center;
  margin-top: 50px;
}

.not-found h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.not-found p {
  margin-bottom: 20px;
}

.not-found ul {
  list-style-type: none;
  padding: 0;
}

.not-found ul li {
  margin: 10px 0;
}

.not-found a {
  text-decoration: none;
  color: #007bff;
}

.not-found a:hover {
  text-decoration: underline;
}
/* Example of CSS animation for blinking eyes */
@keyframes blink {
  0%,
  50%,
  100% {
    transform: scaleY(1);
  }
  25%,
  75% {
    transform: scaleY(0);
  }
}

.not-found svg {
  /* Adjust selector as per your emoji */
  animation: blink 2s infinite linear; /* Apply blink animation to the emoji */
}
